import * as React from "react";

const RadioLuzLogo = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 276 74"
      aria-labelledby="title"
      {...props}
    >
      <title id="title">Logo Radia Luz</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-13.000000, -13.000000)" fill="currentColor">
          <g transform="translate(13.873500, 13.844800)">
            <polygon points="208.0757 27.76 190.7207 27.76 190.7207 0 176.8637 0 176.8637 41.615 221.9307 41.615 221.9307 0 208.0757 0"></polygon>
            <polygon points="252.2017 58.9553 274.1327 27.7593 229.0617 27.7603 229.0617 41.6153 247.2457 41.6153 225.4157 72.8103 274.1447 72.8103 274.1447 58.9553"></polygon>
            <polygon points="13.855 24.2756 0 24.2756 0 69.3426 180.332 69.3426 180.332 55.4866 13.855 55.4866"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RadioLuzLogo;
