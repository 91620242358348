import * as React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { AccessibilityColorsContext } from "./Layout";

const headerStyle = css`
  position: sticky;
  top: 0;
  z-index: 999999;
`;

const localMenuContainerStyle = theme => css`
  li + li:before {
    content: " / ";
    display: inline;
    color: inherit;
  }

  ${theme.breakpoints.mobile} {
    display: none;
  }
`;

const navStyle = (foreground, background) => (theme) =>
  css`
    padding: 0 20px;
    background-color: ${background};
    display: flex;

    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid ${foreground};

    ul {
      ${theme.typography.navigation};
      list-style-type: none;
      margin: 0;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      color: ${foreground};

      ${theme.breakpoints.mobile} {
          flex-grow: 1;
          justify-content: center;
        }

      a,
      button {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        padding: 10px 20px;

        &:hover {
          color: ${theme.colors.whiteText};
        }

        ${theme.breakpoints.tablet} {
          padding: 10px 10px;
        }

        ${theme.breakpoints.mobile} {
          padding: 10px 10px;
        }
      }
    }
  `;

const accessibilitySwitch = (theme) => css`
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: ${theme.colors.black}!important;
  color: ${theme.colors.white}!important;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  line-height: 1.3em;
`;

const Navigation = ({
  hideBack = false,
  foreground = "#000",
  background = "transparent",
  children,
  language,
  languageUrl,
}) => {
  const { accesibleMode, setAccessibleMode } = React.useContext(
    AccessibilityColorsContext
  );
  return (
    <header css={headerStyle}>
      <nav css={navStyle(foreground, background)}>
        {typeof children !== "undefined" ? (
          <div css={localMenuContainerStyle}>{children}</div>
        ) : (
          <span />
        )}
        <ul>
          {!hideBack && (
            <li>
              <Link to="/">{language === "pl" ? "Wróć" : "Back"}</Link>
            </li>
          )}
          {!!languageUrl && (
            <li>
              <Link to={languageUrl} hrefLang={language === 'pl' ? 'en' : 'pl'}>
                {language === "pl" ? "English" : "Polski"}
              </Link>
            </li>
          )}
          <li>
            <button
              href="#"
              css={accessibilitySwitch}
              onClick={() => setAccessibleMode(!accesibleMode)}
              role="switch"
              aria-checked={accesibleMode}
            >
              {language === "en" ? "Accessibility mode" : "Tryb dostępności"}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
