import React from "react"
import { Helmet } from "react-helmet"

function SEO({ description = '', meta = [], title, language = 'pl' }) {
    const titleTemplate = language === 'pl' ? 'GlassGo2 - gra miejska' : 'GlassGo2 - city game'
    const defaultDescription = language === 'pl' ? 'GlassGo2 to gra miejska rozgrywająca się we Wrocławiu' : 'GlassGo2 is a city game set in Wrocław'
  const metaDescription = description || defaultDescription

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      titleTemplate={`%s | ${titleTemplate}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
            name: `keywords`,
            content: "wroclaw, bwa, gry miejskie",
          },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
