import * as React from "react";

const BwaLogo = (props) => {
  return (
    <svg
      viewBox="0 0 91 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>BWA Wrocław logo</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-253.000000, -356.000000)" fill="#1A1919">
          <g transform="translate(253.134300, 356.475700)">
            <path
              d="M1.42108547e-14,86.4741 L4.346,86.4741 L7.285,79.1891 L21.44,79.1891 L24.378,86.4741 L28.89,86.4741 L16.68,57.5841 L12.334,57.5841 L1.42108547e-14,86.4741 Z M20.115,75.7961 L8.609,75.7961 L14.362,61.4751 L20.115,75.7961 Z"
              id="Fill-1"
            ></path>
            <path d="M2.9964,28.89 L10.6944,28.89 C16.5724,28.89 21.7044,27.4 21.7044,21.192 C21.7044,17.135 18.9314,14.569 14.9994,14.238 L14.9994,14.155 C18.6414,13.245 20.9594,11.258 20.9594,7.285 C20.9594,1.945 16.8204,0 10.8194,0 L2.9964,0 L2.9964,28.89 Z M7.1354,15.728 L9.8254,15.728 C13.3854,15.728 17.3174,16.349 17.3174,20.819 C17.3174,24.71 13.9644,25.496 10.2394,25.496 L7.1354,25.496 L7.1354,15.728 Z M7.1354,3.394 L11.4814,3.394 C13.8814,3.394 16.8204,4.263 16.8204,7.699 C16.8204,11.962 13.4264,12.334 9.4114,12.334 L7.1354,12.334 L7.1354,3.394 Z"></path>
            <polygon
              id="Fill-4"
              points="56.5162 56.1441 62.0622 56.1441 68.3952 30.8961 68.4782 30.8961 74.7692 56.1441 80.1082 56.1441 89.0072 27.2541 84.6192 27.2541 77.7072 52.0051 77.6252 52.0051 71.3752 27.2541 65.4982 27.2541 59.3302 52.0051 59.2482 52.0051 52.3362 27.2541 47.7832 27.2541"
            ></polygon>
            <polygon
              id="Fill-7"
              points="51.8417 64.4863 85.3267 64.4863 85.3267 60.3153 51.8417 60.3153"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BwaLogo;
