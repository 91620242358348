import * as React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import BwaLogo from "./bwaLogo";
import RadioLuzLogo from "./RadioLuzLogo";
import MKDNiSLogo from "./MKDNiSLogo";
import biuroWsplonychAnimacjiLogo from "./images/biuro_wspolnych_animacji.png";
import WroclawMiatoSpotkanLogo from "./WroclawMiatoSpotkanLogo";

const containerStyle = (theme) => css`
  background-color: ${theme.colors.red};

  a {
    color: ${theme.colors.blackText};
  }

  padding-top: 150px;
  padding-bottom: 20px;

  ${theme.breakpoints.tablet} {
    padding-top: 100px;
  }

  ${theme.breakpoints.mobile} {
    padding-top: 20px;
  }
`;

const firstLineContainer = css`
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  align-items: flex-start;
  padding: 0 20px;
  flex-wrap: wrap;
`;

const secondLineContainer = (theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  font-size: 0.6em;
  column-gap: 15px;
  margin-top: 50px;
  padding: 0 20px;

  svg,
  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  ${theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const itemsContainerStyle = (theme) => css`
  display: flex;
  flex-grow: 1;
  border-top: 1px solid ${theme.colors.black};
  border-bottom: 1px solid ${theme.colors.black};

  ${theme.breakpoints.tablet} {
    flex-direction: column;
    border-right: 1px solid ${theme.colors.blackText};
  }

  ${theme.breakpoints.mobile} {
    margin-top: 20px;
  }

  & > div {
    padding: 10px 20px;
  }

  & > div,
  a {
    border-left: 1px solid ${theme.colors.black};
  }

  & > div:first-of-type {
    border-left: 0;

    ${theme.breakpoints.tablet} {
      border-left: 1px solid ${theme.colors.black};
    }
  }
`;

const footerMenuStyle = (theme) => css`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;

  ${theme.breakpoints.tablet} {
    border-top: 1px solid ${theme.colors.blackText};
  }

  a {
    display: inline-block;
    color: ${theme.colors.blackText};
    text-decoration: none;
    padding: 10px 20px;
    height: 100%;

    &:hover {
      color: ${theme.colors.whiteText};
    }
  }
`;

const luzRoleStyle = css`
  display: block;
  text-decoration: none;
  font-size: .5rem;
  margin-bottom: 10px;
`

const financialNoteStyle = css`
  margin-top: 0;
`;

const logoContainer = (theme) => css`
  margin-right: 100px;

  ${theme.breakpoints.tablet} {
    margin-right: 50px;
  }
`;

const rodoItemStyle = css`
  flex-grow: 1;
`;

const Footer = ({ language, addSecondLine = false }) => {
  return (
    <footer css={containerStyle}>
      <div css={firstLineContainer}>
        <div css={logoContainer}>
          <BwaLogo height="50px" />
        </div>
        <div css={itemsContainerStyle}>
          <div>
            {language === "pl"
              ? "GlassGo2 - gra miejska"
              : "GlassGo2 - city game"}
          </div>
          <div>© 2021</div>
          <ul css={footerMenuStyle}>
            <li css={rodoItemStyle}>
              <Link to="/rodo">Rodo</Link>
            </li>
            <li>
              <a
                href="https://instagram.com/bwawroclaw"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Insta
              </a>
            </li>
            <li>
              <a
                href="https://facebook.com/bwawroclaw"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.bwa.wroc.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                {language === "pl" ? "Kontakt" : "Contact"}
              </a>
            </li>
          </ul>
        </div>
      </div>
      {addSecondLine && (
        <div css={secondLineContainer}>
          <a
            href="https://bwa.wroc.pl/2021/06/17/biuro-wspolnych-aspiracji/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={biuroWsplonychAnimacjiLogo}
              alt="logo BWA - biuro wspólnych aspiracji"
            />
          </a>
          <div>
            <a
              href="http://radioluz.pwr.edu.pl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span css={luzRoleStyle}>Patronat medialny:</span>
              <RadioLuzLogo height="30" />
            </a>
          </div>
          <p css={financialNoteStyle} lang="pl">
            Działanie jest częścią projektu Biuro Wspólnych Aspiracji.
            Dofinansowano ze środków Ministra Kultury, Dziedzictwa Narodowego i
            Sportu pochodzących z Funduszu Promocji Kultury. Dofinansowano ze
            środków Gminy Wrocław.
          </p>
          <p css={financialNoteStyle} lang="en">
            The activity is part of the Office of Common Aspirations project.
            Co-financed by the Ministry of Culture, National Heritage and Sport
            from the Culture Promotion Fund. Co-financed by the Municipality of
            Wrocław.
          </p>
          <div>
            <a
              href="https://www.gov.pl/web/kulturaisport"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MKDNiSLogo height="50" />
            </a>
          </div>
          <div>
            <a
              href="https://www.wroclaw.pl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WroclawMiatoSpotkanLogo height="50" />
            </a>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
